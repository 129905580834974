<script setup lang="ts">
const localePath = useLocalePath()

const PRODUCT_IMAGES = [
  'image_1.png', 'image_2.png', 'image_3.png', 'image_4.png', 'image_5.png',
  'image_6.png', 'image_7.png', 'image_8.png', 'image_9.png', 'image_10.png',
  'image_11.png', 'image_12.png', 'image_13.png', 'image_14.png', 'image_15.png',
  'image_16.png', 'image_17.png', 'image_18.png', 'image_19.png', 'image_20.png',
  'image_21.png', 'image_22.png', 'image_23.png', 'image_24.png', 'image_25.png',
  'image_26.png', 'image_27.png', 'image_28.png', 'image_29.png', 'image_30.png',
  'image_31.png', 'image_32.png', 'image_33.png', 'image_34.png', 'image_35.png',
  'image_36.png', 'image_37.png', 'image_38.png', 'image_39.png', 'image_40.png',
  'image_41.png', 'image_42.png', 'image_43.png', 'image_44.png', 'image_45.png',
  'image_46.png', 'image_47.png', 'image_48.png', 'image_49.png', 'image_50.png',
  'image_51.png', 'image_52.png', 'image_53.png', 'image_54.png', 'image_55.png',
  'image_56.png', 'image_57.png', 'image_58.png', 'image_59.png', 'image_60.png',
  'image_61.png',
]

  .sort(() => Math.random() - 0.5)

function selectRandomImages(allImages: string[], count: number) {
  const shuffled = [...allImages].sort(() => 0.5 - Math.random())
  return shuffled.slice(0, count)
}
</script>

<template>
  <div>
    <div class="relative h-80 w-full pb-[33.3333%] transition-all duration-500 ease-in-out md:h-64">
      <div class="absolute inset-0 h-full w-full">
        <div class="relative h-full w-full">
          <div class="absolute h-full w-full flex shrink-0 items-center justify-center overflow-hidden">
            <div class="grid grid-cols-4 gap-5 lg:grid-cols-9 sm:gap-8">
              <template v-for="(image, index) in selectRandomImages(PRODUCT_IMAGES)" :key="image">
                <NuxtImg
                  :src="`/products/featured/${image}`"
                  class="pointer-events-none h-full w-full cursor-none select-none object-cover p2"
                  :class="{
                    'md:transform translate-y-4': index % 2 === 1,
                    'md:-translate-y-6': index % 2 === 0,
                  }"
                />
              </template>
            </div>
            <div class="absolute inset-0 bg-black/50" />
          </div>
          <div
            class="absolute top-0 h-full w-full flex flex-col justify-between overflow-hidden from-black/60 via-black/30 to-black/60 bg-gradient-to-b px-4 md:px-8"
          >
            <div class="w-full flex flex-col items-center text-center">
              <h1 class="mt-10 text-[1.5rem] color-white lg:mt15 lg:text-[2.5rem] md:mt5 md:text-[1.5rem]">
                {{ $t('trustable.everything_nearby') }}<br>{{ $t('trustable.at_one_click') }}
              </h1>
              <StoreLogo class="dark:text-whit mt4 h-8 w-auto text-slate-900" />
            </div>
            <div>
              <div class="flex flex-col items-center self-end justify-center flex-gap-4 pb-4 sm:flex-row">
                <a
                  href="#bestsellers"
                  class="min-w-40 rounded-md bg-white px-4 py-2 text-center text-sm text-gray-900 font-bold shadow-sm ring-1 ring-gray-300 ring-inset hover:bg-gray-50"
                >
                  {{ $t('trustable.bestsellers') }}
                </a>
                <NLink
                  class="min-w-40 rounded-md bg-white px-4 py-2 text-center text-sm text-gray-900 font-bold no-underline shadow-sm ring-1 ring-gray-300 ring-inset hover:bg-gray-50"
                  href="search?q=zimní"
                >
                  <NIcon icon="ph:snowflake" />
                  {{ $t('trustable.winter_bestsellers') }}
                </NLink>
              </div>
              <div
                class="hidden flex-col items-center gap-y-2 py-3 color-white lg:flex lg:flex-row lg:justify-center lg:flex-gap-[15%]"
              >
                <InlineWithIcon
                  icon-code="ph:gift"
                  icon-class="w-4 h-4"
                  class="font-bold"
                  :text="$t('trustable.gift_for_every_order')"
                />
                <InlineWithIcon
                  icon-code="ph:package"
                  icon-class="w-4 h-4"
                  class="font-bold"
                  :text="$t('trustable.express_24_shipping')"
                />
                <InlineWithIcon
                  icon-code="ph:truck"
                  class="font-bold"
                  icon-class="w-4 h-4"
                  :text="$t('trustable.free_shipping')"
                />
                <InlineWithIcon
                  icon-code="ph:arrows-clockwise"
                  class="font-bold"
                  icon-class="w-4 h-4"
                  :text="$t('trustable.return_in_100_days')"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
